@media (max-width: 480px) {

    html, body {
        font-size: 2.9vw;
        line-height: 3.6vh;

    }

    .button {
        height: 5.65vh;
    }
}