.container {
    /*background-color: #282a36;*/
}

.content {
    /*background-color: #EFBE0F;*/
    border: 1px solid #E0E0E0;
}

.circle {
    width: 4vw;
    height: 4vw;
    border-radius: 100%;
    background-color: var(--cardHeader);
}

.rectangle {
    height: 6.5vh;
    background-color: var(--cardHeader);
}