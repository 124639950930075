.container {
    /*background-color: #EFBE0F;*/
}

.logo {
    width: 25%;
}
.title {
    font-weight: 900;
}
.launchButton {
    width: 10vw;
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
    box-shadow: 0px 0px 29px 5px rgba(56, 98, 221, 0.44);

}

.box {
    width: 5.7vw;
    height: 5.7vw;
    background-color: var(--green);
}