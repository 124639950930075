.container {
    border-bottom: 1px solid #E0E0E0;
    padding: 2vh 1vw;
}



.circle {
    width: 4vw;
    height: 4vw;
    border-radius: 100%;
    /*background-color: var(--cardHeader);*/

}

.rate {
    margin-left: 2vw;
}

.rectangle {
    padding: 0 1vw;
}